import React, { Component } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import logo from "../images/default-logo.png";
import ReactImageFallback from "react-image-fallback";
import withUnmounted from '@ishawnwang/withunmounted'
import Checkout from '../classes/checkout';

class CheckoutHeader extends Component {
  constructor(props) {
    super(props);
    this.img = logo;
    this.state = { cartInfo: null, step: "info" , fullPath : (this.props.fullPath ? this.props.fullPath : false)};
  }

  componentWillReceiveProps(props) {

    this.setState({ cartInfo: props.cartInfo,step: props.step });
   
  }

  async componentDidMount(){
    if(!this.state.cartInfo){
      this.setState({...await Checkout.getCartDataAtLocal()});
    }
  }

  async componentWillMount(){
    if(!this.state.cartInfo){
      this.setState({...await Checkout.getCartDataAtLocal()});
    }
  }

  render() {
    var breadcrumbs = this.props.breadcrumbs ? this.props.breadcrumbs:  [
      {
        key: "info",
        label: "Customer Information",
        link: "/",
        className: "breadcrumb-item "
      },
      {
        key: "pay",
        label: "Shipping & Payment",
        link: "/payment",
        className: "breadcrumb-item "
      }
    ];

    return (
      <div className="checkout-title dis-n-1000">

                   { this.state.cartInfo && this.state.cartInfo.shopDetails && <ReactImageFallback
                    src={this.state.cartInfo.shopDetails.logo
                      }
                    fallbackImage={logo}
                    alt="Store"
                    className="img-responsive center-block img-loader-z" style={{"width": (this.state.cartInfo.shopDetails.logo ? this.props.checkout_enable_single_page ? "130px" : "auto" : this.props.checkout_enable_single_page ? "130px" : "464.8px"),"height": (this.state.cartInfo.shopDetails.logo ? "auto" : this.props.checkout_enable_single_page ? "auto" : "147.7px")}}/>}
{ this.props.checkout_enable_single_page ? <span className='text-uppercase'>Checkout</span> : ''}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.length > 0 && !this.props.checkout_enable_single_page &&
              breadcrumbs.map((b, i) => {
                return (
                  <li
                    key={b.key}
                    className={
                      b.className + (b.key === this.state.step ? " active" : "")
                    }
                  >
                    <Link to={b.link}>{b.label}</Link>
                  </li>
                );
              }, this)}
          </ol>
        </nav>
      </div>
    );
  }
}

export default withUnmounted(CheckoutHeader);


import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from '../components/Skeleton';
import { isMobile } from "react-device-detect";
import CheckoutHeader from './CheckoutHeader';
import ShippingAddresses from './ShippingAddresses';
import CustomerPage from './CustomerPage';
import PaymentPage from './PaymentPage';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { withRouter } from "react-router-dom";
import {setLlHash, getLlConfig, getCrmConfig} from '../store/actions/llConfigActions';
import SidebarSkeleton from './SidebarSkeleton';

const mapStateToProps = (state) => {
    return {
        llConfig: state.llConfig
    };
}

function mapDispatchToProps(dispatch) {
    return {
      callSetLlHash: (lLHash) => dispatch(setLlHash(lLHash)),
      callGetLlConfig: (lLHash) => dispatch(getLlConfig(lLHash)),
      callGetCrmConfig: (lLHash) => dispatch(getCrmConfig(lLHash))
    };
}

class CheckoutComponent extends Component {
    componentWillMount = async () => {
        try{
            let llHash = this.props.match.params.llHash;
            await this.props.initiateConfig(llHash);
        }catch(e){
            console.log("Error",e);
        }
    }

    renderSkeleton = () => {
        return (<Skeleton></Skeleton>);
    }

    renderCheckout = () => {
        return (
            <div>
                {
                this.props.llConfig.cartData && 
                <div>
                <div className={`bg-white  ${this.props.llConfig.cartData.checkout_enable_single_page ? 'cstm-width' : '' }`}>
                    <div className="left-div width100"></div> {/*For mobile view*/}
                    {!this.props.llConfig.cartData.checkout_enable_single_page ? 
                        <div className="left-div">
                        <CheckoutHeader
                        updateStep={this.props.updateStep}
                        cartInfo={this.props.llConfig.cartData}
                        checkout_enable_single_page={false}
                        step={this.props.step}
                        breadcrumbs={[
                            {
                            key: "info",
                            label: "Customer Information",
                            link: "/",
                            className: "breadcrumb-item "
                            }
                        ]}
                        />
                        {this.props.token_based_user_login && this.props.showTokenUserShipping  && this.props.token_based_user_addresses && !this.props.llConfig.cartData.hasProspect &&
                        <ShippingAddresses  showTokenUserShippingAddresses={this.props.showTokenUserShippingAddresses} 
                        token_based_user_addresses={this.props.token_based_user_addresses}
                        />}
                        <CustomerPage
                        {...this.props}
                        exitPopupcounter={this.props.exitPopupcounter}
                        exitPopupVisiblity={this.props.exitPopupVisiblity}
                        exitIntentShow={this.props.exitIntentShow}
                        countries={this.props.countries}
                        updateStep={this.props.updateStep}
                        updateTax={this.props.updateTax}
                        handleAccordianOnClick={this.props.handleAccordianOnClick}
                        checkout_enable_single_page={false}
                        isMobile={isMobile}
                        showTokenUserShipping ={this.props.showTokenUserShipping && !this.props.llConfig.cartData.hasProspect}
                        showTokenUserShippingAddresses={this.props.showTokenUserShippingAddresses}
                        selected_token_user_details={this.props.selected_token_user_details}
                        tokenUserPhone={this.props.tokenUserPhone}
                        token_based_user_login={ this.props.token_based_user_login}
                        token_based_user_addresses={this.props.token_based_user_addresses}
                        />
                    </div>
                        :
                        <div className="left-div one-page-checkout">
                        <CheckoutHeader
                        updateStep={this.props.updateStep}
                        cartInfo={this.props.llConfig.cartData}
                        checkout_enable_single_page={true}
                        step={this.props.step}
                        breadcrumbs={[
                            {
                            key: "info",
                            label: "",
                            link: "/",
                            className: "breadcrumb-item "
                            }
                        ]}
                        />
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                            <div class="card-header form_btn_bg" id="headingOne">
                                <h2 class="mb-0" onClick={(e) => this.props.handleAccordianOnClick('customer-info')}>
                                <div className="form_btn_link">
                                SHIPPING  <small className='card-header-subtext'>Email, Phone, Shipping</small>
                                </div>
                                </h2>
                            </div>

                            <div id="collapseOne" className={`collapse ${this.props.pageToShow === 'customer-info' ? 'show animated slideInDown' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                {this.props.token_based_user_login && this.props.showTokenUserShipping && !this.props.llConfig.cartData.hasProspect && <ShippingAddresses  showTokenUserShippingAddresses={this.props.showTokenUserShippingAddresses} 
                                    token_based_user_addresses={this.props.token_based_user_addresses}
                                />}
                                <CustomerPage
                                    {...this.props}
                                    exitPopupcounter={this.props.exitPopupcounter}
                                    exitPopupVisiblity={this.props.exitPopupVisiblity}
                                    exitIntentShow={this.props.exitIntentShow}
                                    countries={this.props.countries}
                                    updateStep={this.props.updateStep}
                                    updateTax={this.props.updateTax}
                                    handleAccordianOnClick={this.props.handleAccordianOnClick}
                                    checkout_enable_single_page={true}
                                    isMobile={isMobile}
                                    showTokenUserShipping ={this.props.showTokenUserShipping && !this.props.llConfig.cartData.hasProspect}
                                    showTokenUserShippingAddresses={this.props.showTokenUserShippingAddresses}
                                    selected_token_user_details={this.props.selected_token_user_details}
                                    tokenUserPhone={this.props.tokenUserPhone}
                                    token_based_user_login={ this.props.token_based_user_login}
                                    token_based_user_addresses={this.props.token_based_user_addresses}
                                />
                                </div>
                            </div>
                            </div>
                            <div class="card">
                            <div class="card-header form_btn_bg" id="headingTwo">
                                <h2 class="mb-0" onClick={(e) => this.props.handleAccordianOnClick('payment-info')}>
                                <div className='form_btn_link'>
                                PAYMENT <i className='fa fa-lock'></i> <small className='card-header-subtext'>{this.props.llConfig.cartData.checkout_card_type_accepted ? this.props.llConfig.cartData.checkout_card_type_accepted.join(', ') : 'Credit Card'}</small>
                                </div>
                                </h2>
                            </div>
                            <div id="collapseTwo" className={`collapse ${this.props.pageToShow === 'payment-info' && !this.props.disablePaymentTab ? 'show animated slideInDown' : ''}`} aria-labelledby="headingTwo" data-parent='#accordionExample'>
                                <div class="card-body">
                                <PaymentPage key={this.props.updatePaymentKey}
                                    {...this.props}
                                    updateStep={this.props.updateStep}
                                    countries={this.props.countries}
                                    updateShipping={this.props.updateShipping}
                                    updateGiftItem={this.props.updateGiftItem}
                                    cartInfo={this.props.llConfig.cartData}
                                    exitIntentShow={this.props.exitIntentShow}
                                    exitPopupVisiblity={this.props.exitPopupVisiblity}
                                    setPopException={this.props.setPopException}
                                    squareLoad={this.props.squareLoad}
                                    exitPopupcounter={this.props.exitPopupcounter}
                                    updateTax={this.props.updateTax}
                                    threeDSecureEnable={this.props.threeDSecureEnable}
                                    handleAccordianOnClick={this.props.handleAccordianOnClick}
                                    initiateConfig={this.props.initiateConfig}
                                    checkout_enable_single_page={true}
                                    isMobile={isMobile}
                                    showTokenUserPayment ={this.props.showTokenUserPayment}
                                    showTokenUserPaymentMethod={this.props.showTokenUserPaymentMethod}
                                    userTokenShowListPaymentpage={this.props.userTokenShowListPaymentpage}
                                    token_based_user_addresses={this.props.token_based_user_addresses}
                                    token_based_user_payments={this.props.token_based_user_payments}
                                    token_based_user_bill_add={this.props.token_based_user_bill_add}                                          
                                    token_based_user_login={ this.props.token_based_user_login}
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    }
                    </div>
                <div className={`bg-gray  ${this.props.llConfig.cartData.checkout_enable_single_page ? 'background-white' : '' }`}>
                    {this.props.llConfig.cartData.disable_submit ? <SidebarSkeleton /> : <Sidebar isMobile={isMobile} {...this.props} updateStep={this.props.updateStep} updateTax={this.props.updateTax} tax={this.props.tax} updateCart={this.props.updateCart}  updateCartItemQuantity={this.props.updateCartItemQuantity} cartInfo={this.props.llConfig.cartData} manageCartSubTotalAmount={this.props.manageCartSubTotalAmount} removeCartItem={this.props.removeCartItem}/>}
                </div>
                <Footer timerStart={this.props.timerStart} cartInfo={this.props.llConfig.cartData} />
                </div>
                }
            </div>
        );
    }

    render(){
        if(this.props.llConfig.loading)
        {
            return this.renderSkeleton();
        }
        else
        {
            return this.renderCheckout();
        }
    }
}

const ConnectedCheckoutComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutComponent);

export default withRouter(ConnectedCheckoutComponent);
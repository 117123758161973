import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'

class GiftForm extends Component{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    
        this.state = {
            gift_email:this.props.formState.gift_email,
            gift_message:this.props.formState.gift_message
        }        
    }



    onChange(e) {

        if(e.target.name === "show_gift_form" && this.props.formState.show_gift_form === "yes"){
            e.target.value = false;
        }
        this.props.onChangeEvent(e);
    }

    render() {
        
        return( 
        
            <React.Fragment>
                {this.props.cartInfo && this.props.cartInfo.checkout_enable_gift_notification === true && <div className="contact-information">
                    <p className="title-text"></p>
                    <div className="billign-address">
                        <div className="contact-form billing-form">
                                <div className="custom-control custom-radio">
                                    {/* Radio */}
                                    <input type="checkbox" className="form-check-input" id="gift-checkbox" value="yes" name="show_gift_form" onChange={(e)=>this.onChange(e)}/>
                                    <label className="form-check-label" htmlFor="gift-checkbox">This is a gift </label>

                                    {/* Form */}

                                    {this.props.formState.show_gift_form === "yes" && <div className="contact-frm form-row" style={{"marginTop":"20px"}}>
                                                    <div className="form-group col-lg-12">
                                                    <input type="text" 
                                                            name="gift_email" 
                                                            className={"form-control" + (this.props.giftError.gift_email.status === true ? " is-invalid":"")} 
                                                            placeholder="Enter gift email"
                                                            value={this.props.formState.gift_email}
                                                            onChange={e => this.onChange(e)}
                                                            />
                                                            {
                                                                this.props.giftError.gift_email.status === true ? <div className="shipping-validation error">
                                                                {this.props.giftError.gift_email.message}
                                                              </div>:""
                                                            }
                                                </div>    
            
                                                    <div className="form-group col-lg-12">
                                                    <textarea name="gift_message" 
                                                                className={"form-control" + (this.props.giftError.gift_message.status === true ? " is-invalid":"")}
                                                                placeholder="Enter gift message" 
                                                                onChange={e => this.onChange(e)} value={this.props.formState.gift_message}></textarea>
                                                            {
                                                                this.props.giftError.gift_message.status === true ? <div className="shipping-validation error">
                                                                {this.props.giftError.gift_message.message}
                                                              </div>:""
                                                            }
                                                </div>    
                                    </div>}


                                
                                </div>
                        </div>

                        

                    </div>
                </div>}
            </React.Fragment>
            
           
        )
    }
}

export default withUnmounted(GiftForm);



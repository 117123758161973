import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'
import PayWithAmazon from "../classes/PayWithAmazon"
import swal from 'sweetalert';
import ls from "local-storage";
import {connect} from 'react-redux';
import payNowButton from '../images/amazon-pay-now.png';
ls.backend(sessionStorage);

const mapStateToProps = (state) => {
    return {
        llConfig: state.llConfig
    };
}

class AmazonPayProcess extends Component {
    constructor(props) {
        super(props);
        this.closeModel = this.closeModel.bind(this);
        this.processPayment = this.processPayment.bind(this);
        this.setAmazonPayOnlocal = this.setAmazonPayOnlocal.bind(this);
        this.state = {
                        sellerId:'',
                        clientId:'',
                        region:'us',
                        scriptLoaded:false,
                        amazonAccessToken:'',
                        amazonOrderReferenceId:'',
                        amazonBillingAgreementId:'',
                        production:true,
                        openPaymentOption:false,
                        billingConsent:false,
                    }
    }

    setAmazonPayOnlocal(amazonPay){
        ls.set("amazonPay", amazonPay);
    }
  
    async componentWillMount(){
        try{ 
            await this.setLocalState();
            var openPaymentOption = this.props.openPaymentOption ;

            var payWithAmazon = new PayWithAmazon({
                sellerId: this.state.sellerId,
                clientId: this.state.clientId,
                button: { id: 'pay-with-amazon', type: 'large', color: 'Gold' },
                addressBook: 'address-book',
                wallet: 'wallet',
                consent: 'consent',
                region: this.state.region,
                production: this.state.production
              });

              var self = this;
              
              payWithAmazon.on('ready.addressBook',function(res){
                if(!res.getAmazonOrderReferenceId()){
                    return false;
                }
                
                let amazonPay = ls.get("amazonPay") ? ls.get("amazonPay") : {processPayment:false};
                amazonPay.amazonOrderReferenceId = res.getAmazonOrderReferenceId();
                amazonPay.amazonBillingAgreementId = res.getAmazonContractId();
                amazonPay.openPaymentOption = openPaymentOption;
                window.parent.amazonPay = amazonPay;
                self.setAmazonPayOnlocal(amazonPay);
                self.setState(amazonPay);
                
              });

              payWithAmazon.on('login',function(res){
                let amazonPay = ls.get("amazonPay") ? ls.get("amazonPay") : {processPayment:false};
                amazonPay.amazonAccessToken = res.access_token;
                self.setAmazonPayOnlocal(amazonPay);
                self.setState(amazonPay);
              })

              payWithAmazon.on("error",function(error){
                    self.closeModel(error);
                    self.setAmazonPayOnlocal({});
                    swal({
                        title: "OOPS!",
                        text: "Amazon Pay is not available at this moment, please process with other available payment option",
                        icon: "error",
                    });
              });

              payWithAmazon.on("change",function(res){
                  self.setState({billingConsent:(res && 'consent' in res ? res.consent : false)});
              });
        }catch(e){
            console.log("Error",e);
        }
    }

    async componentDidMount(){
        try{
            if(!this.props.openPaymentOption){
                return false;
            }
            var intervalID = setInterval(function(){
                
                if(document.getElementsByClassName("amazonpay-button-inner-image").length>0){
                    document.getElementsByClassName("amazonpay-button-inner-image")[0].click()
                    clearTimeout(intervalID);
                }
                
            }, 200);
        }catch(e){
            console.log("Exception");
        }
    }

    setLocalState(){
        try{
            var self = this;
            var cartInfo = self.props.llConfig.cartData;
            if(!cartInfo){
                return false;
            }
            
             new Promise(function(resolve, reject) {
                self.setState({
                    sellerId: cartInfo.shopDetails && cartInfo.shopDetails.amazon_pay_details && cartInfo.shopDetails.amazon_pay_details.sellerId ? cartInfo.shopDetails.amazon_pay_details.sellerId:'',
                    clientId: cartInfo.shopDetails && cartInfo.shopDetails.amazon_pay_details && cartInfo.shopDetails.amazon_pay_details.clientId ? cartInfo.shopDetails.amazon_pay_details.clientId : '',
                    region:   cartInfo.shopDetails && cartInfo.shopDetails.amazon_pay_details && cartInfo.shopDetails.amazon_pay_details.region ? cartInfo.shopDetails.amazon_pay_details.region :'us',
                    production: cartInfo.shopDetails && cartInfo.shopDetails.amazon_pay_details && cartInfo.shopDetails.amazon_pay_details.sandbox ? false: true

                },function(){
                    resolve();
                });
            });

            
        }catch(e){
            console.log("Error Amazon pay",e);
        }
    }
    
    closeModel(e){
        
        this.setState({openPaymentOption:false,amazonAccessToken:'',amazonOrderReferenceId:'',amazonBillingAgreementId:''});
    }

    processPayment(){
        try{
            if(!this.state.billingConsent){
                swal({
                    title: "OOPS!",
                    text: 'Please check on "Use my selected payment method for future purchases and payments to this merchant." ' ,
                    icon: "error",
                });
                return false;
            }
            
            let amazonPay = ls.get("amazonPay") ? ls.get("amazonPay") : {processPayment:false};
                amazonPay.processPayment = true;
                this.setAmazonPayOnlocal(amazonPay);
                window.parent.amazonPayResponse = amazonPay; 
                window.close();
                
        }catch(e){
            console.log("Amazon pay error:",e);
        }
    }
    //render html
    render(){
        return (
            <div>
                
                 <div id="pay-with-amazon" style={{'display':'none'}}></div>
                 
                 
                 
                    {this.props.openPaymentOption && <div className={this.state.openPaymentOption ? "modal fade  custom-modal show" :"modal fade custom-modal"} style={{"display":(this.state.openPaymentOption ? "block":"none")}} tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" style={{ "max-width":"98%"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Amazon Pay</h5>
                                    
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                        <div id="address-book" style={{"height":"300px"}}></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div id="wallet" style={{"height":"300px"}}></div>
                                        </div>
                                        <div className="col-sm-12 mt-2">
                                            <div id="consent" style={{"height":"110px"}}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">

                                    <button className="btn btn-sm" onClick={()=>this.processPayment()}>
                                        <img src={payNowButton} alt=""/>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>}
    
            </div>
        )
    }
}

const ConnectedAmazonPayProcess = connect(
    mapStateToProps,
    null
)(AmazonPayProcess);
export default withUnmounted(ConnectedAmazonPayProcess);
import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Places from "../classes/places";
import Checkout from "../classes/checkout";
import {connect} from 'react-redux';
import ProspectLoader from "../components/ProspectLoader";
import LaddaButton, { L, SLIDE_UP } from "react-ladda";
import swal from 'sweetalert';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import withUnmounted from '@ishawnwang/withunmounted'
import ls from "local-storage";
import googleInstance from "../classes/google"
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import queryString from 'query-string';
import GoogleRecaptcha from "./GoogleRecaptcha";
ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig,
      storeCountries: state.countries
  };
}

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {}, 
      loading: false,
      gCodeCalled:false,
      zipValidate:false,
      userProfileStatus:false,
      customfields: '',
      isVerified: false,
      displayLoader: false
    };
    this.toggle = this.toggle.bind(this);
 
    toast.configure()
    this.manageAffilateKeyAndVal = this.manageAffilateKeyAndVal.bind(this);
    this.formRef = React.createRef();
  }
  verifyCallback = (token)=> {
    if (token) {
      this.setState({ isVerified: true })
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.llConfig && nextProps.llConfig.cartData && nextProps.llConfig.cartData && nextProps.llConfig.cartData.google_id  && this.state.gCodeCalled===false ){
      await googleInstance.beginProcess('begin_checkout');
      this.setState({...this.state,gCodeCalled:true});
    }
    if(this.props.token_based_user_login){
      if(!nextProps.showTokenUserShipping){
        // for add new shipping
        this.changeShippingAddress("",this.props.token_based_user_login,nextProps.tokenUserPhone);
      }
      if(nextProps.selected_token_user_details || nextProps.showTokenUserShipping){
        // for edit shipping
        this.changeShippingAddress(nextProps.selected_token_user_details,this.props.token_based_user_login,nextProps.tokenUserPhone)
      }
    }
    if(this.props.llConfig?.cartData?.hasProspect){
      this.setState({
        initialValues: this.props.llConfig?.cartData?.prospect
      });
    }
  }

  changeShippingAddress(detail, email, phone) {

    let cust_info = ls.get("customerInfo");
    let initVal = {
      "email": email ?? '',
      "phone": phone ?? '',
      "firstName": detail && detail.first_name ? detail.first_name : '',
      "lastName": detail && detail.last_name ? detail.last_name : '',
      "address1": detail && detail.street ? detail.street : '',
      "address2": detail && detail.street_2 ? detail.street_2 : '',
      "city": detail && detail.city ? detail.city : '',
      "country": detail && detail.country ? detail.country : '',
      "state": detail && detail.state ? detail.state : '',
      "zip": detail && detail.zip ? detail.zip : '',
      "address": cust_info && cust_info.address ? cust_info.address : '',
      "pincode": cust_info && cust_info.pincode ? cust_info.pincode : '',
      "password": cust_info && cust_info.password ? cust_info.password : '',
      "shipping": cust_info && cust_info.shipping ? cust_info.shipping : '',
      "confirm_password": cust_info && cust_info.confirm_password ? cust_info.confirm_password : ''
    };                  
      this.setState({...this.state,initialValues: initVal});
  }

  async componentWillMount() {
    ls.remove('prospectId');
    let queries = this.props.location.pathname.indexOf("slug=")>-1 ? this.props.location.pathname : this.props.location.search;
    let urlHash = this.props.location.pathname.indexOf("slug=")>-1 ? this?.props?.match?.params?.llHash : this.props.location.pathname.split("/")[2];
    let query_params = queryString.parse(queries);

    if(ls.get('ll_hash') && urlHash !== ls.get('ll_hash')) {
      //ls.remove('customerInfo'); 
    }

    const { history } = this.props;
    if(!ls.get('customerInfo')) {
      await Checkout.manageLLHash(this.props.match.params);
      await Checkout.manageSession();
    }
    
    let response = (this.props.llConfig.cartData) ? {'cartInfo': this.props.llConfig.cartData} : false;


      if(!response || (response && response.cartInfo.checkout_online_status === false) ){
        history.push("/404");
      }else{
        this.manageAffilateKeyAndVal();
        /** Store URL query params */

        /** Store coupon code to localstorage */
        if('coupon_code' in query_params)
        {
          ls.set("coupon",query_params.coupon_code);
          let isValid = await Checkout.applyDiscountCodeWithOrderTotal(query_params.coupon_code,false);
          
          if(!isValid)
            ls.set("coupon_valid",false);
          else
            ls.set("coupon_valid",true);
        }

        if('ref_coupon' in query_params){
          ls.set("ref_coupon",query_params.ref_coupon);
        }
       
        this.props.updateStep("info");
        
        if(!ls.get('prospectCreated') && this.props.llConfig?.cartData?.hasProspect && this.props.llConfig?.cartData?.prospect){
          this.setState({
            displayLoader:true
          });
          this.formRef.current && this.formRef.current.handleSubmit();
        }else{
          let initVal = Checkout.getProspect();
          initVal.password = initVal && 'password' in initVal ? initVal.password : "";
          initVal.confirm_password = initVal && 'confirm_password' in initVal ? initVal.confirm_password : "";
  
          this.setState({initialValues: initVal});
        }
      }

      if(this.props.exitPopupVisiblity === true){
        this.props.exitIntentShow()
      }
  }

  manageAffilateKeyAndVal(){
    try{
      ls.remove("affiliate");
      let queryStr = queryString.parse(this.props.location.pathname.indexOf("slug=")>-1 ? this.props.location.pathname : this.props.location.search);
      if(Object.keys(queryStr).length<1 )  return false;

      var result = {};
      
      Object.keys(queryStr).map(function(key){
       return result[key.toLowerCase()] = queryStr[key];
      });
    
    ls.set("affiliate",result)
    
    }catch(e){
      console.log("Error",e);
    }
  }

  async componentDidMount(){
    let initVal = await Checkout.getProspect();

    let selectedEmail = '';
    if(this.props.llConfig.cartData.token_based_user_login){
      selectedEmail = this.props.llConfig.cartData.token_based_user_login;
    }

    let email = initVal && initVal.email ? initVal.email : selectedEmail;
    let checkout_enable_shopify_profile_creation = this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_shopify_profile_creation ? this.props.llConfig.cartData.checkout_enable_shopify_profile_creation : false;
    validationSchema['fields']['password'] = Yup.string().notRequired() ;

    if(checkout_enable_shopify_profile_creation === true){
        let userAccount = await Checkout.validateCustomer(email);

        if(userAccount !== true){
          validationSchema['fields']['password'] = Yup.string().required("Enter a valid password");
        }
        var initialValues = this.state.initialValues;
            initialValues.password = "";
            initialValues.confirm_password = "";
        this.setState({...this.state,userProfileStatus:userAccount, initialValues : initialValues});
    }else{
      validationSchema['fields']['confirm_password'] = Yup.string().notRequired() ;
    }
  }

  toggle() {
    this.setState({
      ...this.state,
      loading: !this.state.loading,
      progress: 0.5
    });
  }

  handleSubmit = async function(props, values, actions) {
    try{
      if (!this.state.isVerified && this.props.llConfig.cartData?.checkout_enable_recaptcha) {
       
          swal("Please verify you are human.", {
            position: 'top-end',
            icon: "error"
          });
        
      } else{  

        await this.validateZipCode(values.country, values.zip);
    
        if(!this.state.zipValidate){
          this.formRef.current.setErrors({ zip: "Invalid zip" });
          this.setState({displayLoader: false});
          return false;
        }

        if(this.state.customfields && values.custom_fields){
          values.custom_fields = {...values.custom_fields, ...this.state.customfields};
        }else if(this.state.customfields && !values.custom_fields){
          values['custom_fields'] = this.state.customfields;
        }
    
        this.toggle();
        let smile_io =  ls.get("smile_io");
        let previous_external_id = smile_io && smile_io.customer_detail && smile_io.customer_detail.external_id ? smile_io.customer_detail.external_id : "";

        actions.setSubmitting(true);
        let res = await Checkout.saveProspect(values);
        if(res === false){
          this.toggle();
          this.state.displayLoader && this.setState({displayLoader:false});
          return false;
        }
        let prospect = await Checkout.getProspect();
        let acStatuc = await Checkout.createACinShopify("prospect",values.email);
        if(!acStatuc){
          this.toggle();
          return false;
        }
        
        if(prospect.shipping === ""){
          let shippingKey = this.props.llConfig.cartData && this.props.llConfig.cartData.shippingMethods && Object.values(this.props.llConfig.cartData.shippingMethods).length ? Object.values(this.props.llConfig.cartData.shippingMethods)[0] :{'shipping_method_crm_id':''};
          await Checkout.saveShipping({ shipping: shippingKey.shipping_method_crm_id });
        }

        let checkout_country_based_shipping_enable = this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_country_based_shipping_enable ? this.props.llConfig.cartData.checkout_country_based_shipping_enable : false;

        if(checkout_country_based_shipping_enable){
          await Checkout.saveShipping({ shipping: 0 });
        }
        
        
        let tax = await Checkout.calculateTax();
        this.props.updateTax(tax)
        if (res === true) {
          await googleInstance.beginProcess('generate_lead');
          const { history } = props;

          smile_io =  ls.get("smile_io");
          let new_external_id = smile_io && smile_io.customer_detail && smile_io.customer_detail.external_id ? smile_io.customer_detail.external_id : "";
  
          if(previous_external_id !== "" && new_external_id !== "" && previous_external_id !== new_external_id){
            ls.remove('redeem_coupons_with_amount');
            ls.remove('redeemCoupons');
            this.props.checkout_enable_single_page ? this.props.handleAccordianOnClick('payment-info') : window.location = "/payment";
          }else{
            this.props.checkout_enable_single_page ? this.props.handleAccordianOnClick('payment-info') : history.push("/payment");
          }

          this.toggle();
          ls.set('prospectCreated',true)
        }
        
      }
      } catch (e) {
      console.log("Error: ", e);
    }
  };

  validateZipCode(country, zip){
    try{
      let postCodeValidation = false;
      
      // Check for Zip code will be valid till country is empty
      if(!country){
        this.setState({...this.state,zipValidate:true});
        return;  
      }
      zip = Checkout.validateSpaceForInput(zip, true);
      if(country && zip && postcodeValidatorExistsForCountry(country)){
        postCodeValidation = postcodeValidator(zip,country);
      }

      if(!postCodeValidation && country && zip &&  !postcodeValidatorExistsForCountry(country)){
        postCodeValidation= true;
      }
      
      this.setState({...this.state,zipValidate:postCodeValidation});
    }catch(e){
      console.log(e);
    }
  }

  async validateForm(e){
    try{
      
      let email = e.target.value;
      let checkout_enable_shopify_profile_creation = this.props.llConfig.cartData && 'checkout_enable_shopify_profile_creation' in this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_shopify_profile_creation ? this.props.llConfig.cartData.checkout_enable_shopify_profile_creation : false;
      validationSchema['fields']['password'] = Yup.string().notRequired() ;
      if(checkout_enable_shopify_profile_creation !== true){
        validationSchema['fields']['confirm_password'] = Yup.string().notRequired() ;
      }
      if(email !== "" && checkout_enable_shopify_profile_creation === true){
        let userAccount = await Checkout.validateCustomer(email);

        if(userAccount !== true){
          validationSchema['fields']['password'] = Yup.string().required("Enter a valid password");
        }
        var initialValues = this.state.initialValues;
            initialValues.password = "";
            initialValues.confirm_password = "";
        await this.setState({...this.state,userProfileStatus:userAccount, initialValues : initialValues});
      }

    }catch(e){
      console.log(e);
    }
  }

  async handleCustomFieldChange(e) {
    if(e.target.name.includes("customfields_")){
      let cust_f = e.target.name.split("_");
      let cust_f_k = cust_f[1];
      let customfields = this.state.customfields ? this.state.customfields : {};
      // if value of current element is input_check then use its checked property
      customfields[cust_f_k.toString()] = e.target.value === 'input_check' ? e.target.checked : e.target.value;
      this.setState({...this.state,customfields});
    }
  }

  createInputs(position) {
    let custom_fields = this.props.llConfig.cartData.checkout_custom_fields && this.props.llConfig.cartData.checkout_enable_custom_fields ? this.props.llConfig.cartData.checkout_custom_fields : [];
    return custom_fields.map((el, i) =>
      el.placement === position ? 
      <><div className="form-group col-lg-12">
        {el.field_type_id.toString() === "1" ?
        <Field name={`custom_fields[`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+`]`} type="text" placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} className="form-control"/>
          :'' }
        {el.field_type_id.toString() === "2" ?
        <Field name={`custom_fields[`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+`]`} type="number" placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} className="form-control"/> 
          :'' }
        {el.field_type_id.toString() === "3" ?
        <Field name={`custom_fields[`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+`]`} type="date" placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name}  className="form-control"/>
          :'' }
        {el.field_type_id.toString() === "4" ?
          <div className="contact-form billing-form">
            <div className="custom-control custom-radio">
              <input className="form-check-input" id={`styled-checkbox-${el.sticky_field_id}`} value='input_check' type="checkbox" name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} onChange={e => {this.handleCustomFieldChange(e)}} />
              <label className="form-check-label" for={`styled-checkbox-${el.sticky_field_id}`} >{el.name}</label>
              </div>
            </div>
          :'' }
        {el.field_type_id.toString() === "5" ?
          <div className="selectbox-control">
          <select name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} onChange={e => {this.handleCustomFieldChange(e)}} className="selectpicker form-control"> 
          <option disabled selected>{el.field_placeholder !=='' ? el.field_placeholder : `${el.name}`}</option>
            {          
              el.options.map(opel => {
                return <option value={opel.id}>{opel.value}</option>
            })
            }
          </select>
            <div className="field__caret">
              <i className="fa fa-caret-down" />
            </div>
          </div>  : ''
          }
      </div></> : ''
    );
  }

  render() {
    return (
      <>
      {
        !ls.get('prospectCreated') && this.state.displayLoader && !Object.keys(this.formRef.current.state.errors).length &&
        <ProspectLoader/>
      }
      <Formik
        ref={this.formRef}
        initialValues={this.state.initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) =>
          this.handleSubmit(this.props, values, actions)
        }
        enableReinitialize={true}
      >
        {props => (
          <div className="left-div">
            <Form>
              <div className="contact-information">
              {!this.props.showTokenUserShipping &&
                <h3>Contact information &nbsp;{this.props.checkout_enable_single_page ? <span data-toggle="tooltip" className="tool_tip span-cursor" title="Enter your email ID and phone number to place the order."><i className="fa fa-question-circle "></i></span> : ''}</h3>}
                {
                  !this.props.showTokenUserShipping &&
                <div className="contact-frm form-row">
                  {this.createInputs('before-contact-information')}
                  <div className="form-group col-lg-6">                  
                    <Field
                      name="email"
                      type="text"
                      placeholder="Email"
                      className={
                        "form-control" +
                        (props.errors.email && props.touched.email 
                          ? " is-invalid"
                          : "")
                      }
                      onBlur={(e)=>this.validateForm(e)}
                      disabled={((this.props.llConfig.cartData && !this.props.token_based_user_login) || (this.props.llConfig.cartData.hasProspect)) ? false : true}
                    />
                    {props.errors.email && props.touched.email ? (
                      <div className="error">{props.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg-6">
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      className={
                        "form-control" +
                        (props.errors.phone && props.touched.phone
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.phone && props.touched.phone ? (
                      <div className="error">{props.errors.phone}</div>
                    ) : null}
                  </div>
                  {this.createInputs('after-contact-information')}
                </div>
                 }
              </div>
              {/*-------End home page contact information---------*/}

              {this.props.llConfig.cartData.checkout_enable_shopify_profile_creation && <div className="contact-information">
                <h3 style={{marginBottom:"5px"}}>Account credential</h3>
                <p className="title-text">Credential to create your account</p>
                <div className="contact-frm form-row">
                  <div className="form-group col-lg-6">
                    <Field
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      className={
                        "form-control" +
                        (this.state.userProfileStatus === false && props.errors.password && props.touched.password 
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.state.userProfileStatus}
                    />
                    {this.state.userProfileStatus === false && props.errors.password && props.touched.password ? (
                      <div className="error">{props.errors.password}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg-6">
                    <Field
                      type="password"
                      name="confirm_password"
                      required={!this.state.userProfileStatus}
                      placeholder="Confirm password"
                      className={
                        "form-control" +
                        ( props.errors.confirm_password && props.touched.confirm_password
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.state.userProfileStatus}
                    />
                    { props.errors.confirm_password && props.touched.confirm_password ? (
                      <div className="error">{props.errors.confirm_password}</div>
                    ) : null}
                  </div>
                </div>
              </div>}

              {/*-------home page Shipping Address---------*/}
              <div className="contact-information">
              {!this.props.showTokenUserShipping &&
                <h3>Shipping Address &nbsp;{this.props.checkout_enable_single_page ? <span  data-toggle="tooltip" className="tool_tip span-cursor" title="Enter an address where you want to ship the order."><i className="fa fa-question-circle "></i></span> : ''}</h3>}
                <div className="contact-frm form-row">
                {!this.props.showTokenUserShipping &&<>
               {this.createInputs('before-shipping-information')}
                  <div className="form-group col-md-6">
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      className={
                        "form-control" +
                        (props.errors.firstName && props.touched.firstName
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.firstName && props.touched.firstName ? (
                      <div className="error">{props.errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      className={
                        "form-control" +
                        (props.errors.lastName && props.touched.lastName
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.lastName && props.touched.lastName ? (
                      <div className="error">{props.errors.lastName}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg-12">
                    <Field
                      name="address1"
                      type="text"
                      placeholder="Address"
                      className={
                        "form-control" +
                        (props.errors.address1 && props.touched.address1
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.address1 && props.touched.address1 ? (
                      <div className="error">{props.errors.address1}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg-12">
                    <Field
                      name="address2"
                      type="text"
                      placeholder="Apartment, suite, etc. (optional)"
                      className={
                        "form-control" +
                        (props.errors.address2 && props.touched.address2
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.address2 && props.touched.address2 ? (
                      <div className="error">{props.errors.address2}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-lg-12">
                    <Field
                      name="city"
                      type="text"
                      placeholder="City"
                      className={
                        "form-control" +
                        (props.errors.city && props.touched.city
                          ? " is-invalid"
                          : "")
                      }
                      disabled={this.props.llConfig.cartData ? false : true}
                    />
                    {props.errors.city && props.touched.city ? (
                      <div className="error">{props.errors.city}</div>
                    ) : null}
                  </div>
                  {
                      this.props.llConfig.cartData && <CountrySelect
                      setFieldValue={props.setFieldValue}
                      errors={props.errors}
                      touched={props.touched}
                      {...this.props}
                      initialValues={this.state.initialValues}
                      validateZipCode={(country,zip)=>this.validateZipCode(country,zip)}
                      zipValidate={this.state.zipValidate}
                      location={this.props.llConfig?.cartData?.prospect}
                    />
                  }
               </>  }
               {
                 this.props.llConfig.cartData  && <>
                  {this.createInputs('after-shipping-information')}
                  {this.props.llConfig.cartData?.checkout_enable_recaptcha &&
                  <div className="form-group col-lg-12">
                  <GoogleRecaptcha 
                    verifyCallback={this.verifyCallback} 
                    sitekey={this.props.llConfig.cartData?.checkout_recaptcha_sitekey ? this.props.llConfig.cartData.checkout_recaptcha_sitekey :this.props.llConfig.cartData?.checkout_default_recaptcha_sitekey}/>
                  </div>
                  }
                  <div className="col-md-12">
                    <LaddaButton
                      className="bg-blue"
                      type="submit"
                      loading={this.state.loading}
                      data-size={L}
                      data-style={SLIDE_UP}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                      disabled={(this.props.llConfig.cartData.disable_submit || !(this.props.llConfig.cartData && this.props.llConfig.cartData.shopDetails && this.props.llConfig.cartData.shopDetails.products && Object.keys(this.props.llConfig.cartData.shopDetails.products).length>0))}
                    >
                      Continue to payment
                    </LaddaButton>
                  </div>
                </> }
                  <div className="col-md-12"></div>
                </div>
                {
                  this.props.token_based_user_addresses &&
                  this.props.token_based_user_addresses.length >0 &&
                  this.props.token_based_user_login &&
                  !this.props.showTokenUserShipping &&
                  !this.props.llConfig?.cartData?.hasProspect &&
                  <span 
                    onClick={ e => this.props.showTokenUserShippingAddresses(true)}
                    className="return_btn span-cursor"
                    style= {{"color": "#007bff"}}
                  >
                    <i className="fa fa-angle-left" style= {{"color": "#007bff"}}/>    
                    Return to Shipping list
                  </span>      
                }
              </div>
            </Form>
          </div>
        )}
      </Formik></>
    );
  }
}

const ConnectedCustomerPage = connect(
  mapStateToProps,
  null
)(CustomerPage);

export default withUnmounted(ConnectedCustomerPage);

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  email: Yup.string().trim()
    .email("Enter a valid email")
    .required("Enter a valid email"),
  password:Yup.string().required("Enter a valid password"),
  confirm_password:Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "it's must be same as password"
    )
  }),
  firstName: Yup.string().matches(/^[a-zA-Z_ ]*$/, "First name only contain alphabets" ).trim().required("Enter a first name"),
  lastName: Yup.string().matches(/^[a-zA-Z_ ]*$/, "Last name only contain alphabets" ).trim().required("Enter a last name"),
  address1: Yup.string().required("Enter an address"),
  address2: Yup.string(),
  city: Yup.string().required("Enter a city").trim().matches(/^[a-zA-Z_ ]*$/, "City name only contain alphabets" ),
  state: Yup.string().required("Select a state / province"),
  phone: Yup.string().trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone must be at most 10 characters")
    .required("Enter phone number")
});

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: this.props.storeCountries.countries,
      stateData: [{ code: "", name: "Select State" }],
      emptyStateArr: [{ code: "", name: "Select State" }],
      showState: true,
      selectedCountry: ls.get('customerInfo') ? 
        ls.get('customerInfo').country : 
        props?.location && props?.location?.country ? 
          props.location.country : 
          this.props.llConfig.cartData.checkout_default_country,
      selectedState: props.location ? props.location.state : "",
      zip:props.location ? props.location.zip : props.initialValues.zip,
    };

    if(!ls.get('customerInfo')){
      this.props.initialValues.country = this.props.llConfig.cartData.checkout_default_country;
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.storeCountries){
      var countryList = nextProps.storeCountries.countries;
      this.setState({
        ...this.state,
        countryData: countryList
      });
      let list = await Places.getStatesviaProps(this.props.storeCountries.countries, this.state.selectedCountry);
      this.setState({
        ...this.state,
        stateData: list.length > 0 ? list : this.state.emptyStateArr,
        showState: list.length > 0 ? true : false
      });
    }
  }

  async componentWillMount() {
    if ((this.state.selectedCountry && this.state.selectedCountry.length > 0) || ls.get('customerInfo')) {
      let country = this.state.selectedCountry || ls.get('customerInfo').country;
      this.handleChange({},this.props.setFieldValue,country);
    }

    if(ls.get('customerInfo')){
      this.setState({...this.state,zip:ls.get('customerInfo').zip});
    }

    if(this.props.token_based_user_login && this.props.selected_token_user_details){
      let country = this.props.selected_token_user_details?.country;
      this.handleChange({},this.props.setFieldValue,country);
      let state=this.props.selected_token_user_details?.state;
      this.stateChange(state)
      this.setState({...this.state,zip:this.props.selected_token_user_details?.zip});
    }
  }
 
  async handleChange(e, setFieldValue, country = "") {
    if (country === "") {
      setFieldValue("country", e?.target?.value);
      this.setState({
        ...this.state,
        setstateDetails:""
      })
      setFieldValue("state", "");
      country = e?.target?.value;
    }

    let list = await Places.getStatesviaProps(this.props.storeCountries.countries, country);
    this.setState({
      ...this.state,
      stateData: list.length > 0 ? list : this.state.emptyStateArr,
      showState: list.length > 0 ? true : false,
      selectedCountry: country,
    });

    let defaultSelectedState = (Object.keys(this.state.stateData).length > 1) ? Object.values(this.state.stateData)[0] : false  
    
    if(defaultSelectedState){
      this.setState( {...this.state, selectedState : defaultSelectedState } )
    }

    this.props.setFieldValue("country",country);
  }

  zipChange(e){
    try{
      let zip =  e.target.value
      this.props.setFieldValue("zip", zip);
      this.props.validateZipCode(this.state.selectedCountry, zip);
      this.setState({...this.state,zip:zip});
    }catch(e){
      console.log(e);
    }
  }

  stateChange(e){
    try{
      let state = e?.target?.value ? e.target.value : e;
      if(this.state.stateData && state){
        Object.values(this.state.stateData).forEach(element => {
          if(element && element.code === state){
            ls.set("selectedState",element.name);
          }
        });
      }
      this.props.setFieldValue("country",this.state.selectedCountry);
      this.props.setFieldValue("state",state);
    }catch(e){
      console.log("Error:",e);
    }
  }

  render() {
    return (
      <div className="contact-frm form-row p-r-0 col-lg-12">
        <div className="form-group col-lg-4 select-div">
          <div className="selectbox-control">
            <select
              name="country"
              onChange={e => {
                this.handleChange(e, this.props.setFieldValue,"");
              }}
              className={
                "selectpicker form-control" +
                (this.props.errors.country && this.props.touched.country
                  ? " is-invalid"
                  : "")
              }
              value={this.state.selectedCountry}
            >
              <option 
                value=""
                disabled={this.state.countryData.length===0 ? false : true}
              >
                  Select Country
              </option>

              {this.state.countryData.length > 0 &&
                this.state.countryData.map((item, i) => {return <option key={i} value={item?.code2}>
                        {item?.name}
                      </option>
                }, this)}
            </select>
            <div className="field__caret">
              <i className="fa fa-caret-down" />
            </div>
          </div>
          {this.props.errors.country && this.props.touched.country ? (
            <div className="error">{this.props.errors.country}</div>
          ) : null}
        </div>
        <div className="form-group col-lg-4 select-div">
          <div className="selectbox-control">
            <Field
              component="select"
              name="state"
              className={
                "selectpicker form-control" +
                (this.props.errors.state && this.props.touched.state
                  ? " is-invalid"
                  : "")
              }
              onChange={e => {
                this.stateChange(e);
              }}
            >
              <option value="" key="654896453614654654">Select State</option>
              {this.state.stateData.length > 0 &&
                this.state.stateData.map((item, i) => {
                  return (
                    <option key={i} data-key={i} value={item.code}>
                      {item.name}
                    </option>
                  );
                  
                }, this)}
            </Field>
            <div className="field__caret">
              <i className="fa fa-caret-down" />
            </div>
          </div>

          {this.props.errors.state && this.props.touched.state ? (
            <div className="error">{this.props.errors.state}</div>
          ) : null}
        </div>
        <div className="form-group col-lg-4 p-r-0">
          <Field
            type="text"
            name="zip"
            placeholder="Zipcode"
            className={
              "form-control" +
              (this.props.zipValidate!==true && this.props.touched.zip
                ? " is-invalid"
                : "")
            }
            onChange={ (e)=> { this.zipChange(e)} }
            defaultValue={this.state.zip}
          ></Field>
          
          {this.props.zipValidate!==true && this.props.touched.zip ? (
            <div className="error">Enter valid ZIP code</div>
          ) : null}
        </div>
      </div>
    );
  }
}

import { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'
import Checkout from '../classes/checkout';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadTime : this.props.timerStart -performance.timing.navigationStart, session:false
        }  
    }

    async componentWillReceiveProps(nextProps){
        let session =await Checkout.getSession();
        if(nextProps && session){
            this.setState({session:session});
        }
        
        
      }

    render() {
        
        return '';
    }
}


export default withUnmounted(Footer);        
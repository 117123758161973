import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'

class ExitIntent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkout_exit_popup_html : (this.props.cartInfo.checkout_exit_popup_html) ? this.props.cartInfo.checkout_exit_popup_html : ""
        }
        this.createMarkup = this.createMarkup.bind(this);
    }

    async componentWillReceiveProps(props){
        if(props && props.cartInfo){
            this.setState({ checkout_exit_popup_html : props.checkout_exit_popup_html })
        }
    }

    createMarkup(text) { return {__html: text}; };

    render() {
        return ( <div className={"exit-intent-content"}>
                    {this.props.cartInfo && this.props.cartInfo.checkout_exit_popup_html && <div dangerouslySetInnerHTML={this.createMarkup(this.state.checkout_exit_popup_html)} ></div>}
                 </div> )
    }
}


export default withUnmounted(ExitIntent);
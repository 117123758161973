import React, { Component } from "react";
import Checkout from "../classes/checkout";
import withUnmounted from '@ishawnwang/withunmounted'
import ls from "local-storage";
ls.backend(sessionStorage);

class CheckoutGiftPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
			cartInfo: false,
			selectedValue : false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClearGiftItem = this.handleClearGiftItem.bind(this);
		// Set false onload value 
		ls.set('giftItems', false);
	}
	
	async componentWillReceiveProps(props) {
		if (props.cartInfo) {
			this.setState({ cartInfo: props.cartInfo });
		}
	}

	handleChange(event){
		let cartInfo = this.state.cartInfo;
		let updatedGiftItems = [];

		// Set default value as false before any change in object *giftItems*
		ls.set('giftItems', false);

		// Check for checkbox Checked or not
		Object.keys(cartInfo.checkout_gift_products).forEach((key) => {
			if(cartInfo.checkout_gift_products[key].product_crm_id.toString() === event.target.value.toString()){
				this.setState({ selectedValue : event.target.value });
				let giftItem = {};
				giftItem.description = cartInfo.checkout_gift_products[key].product_description;
				giftItem.image_url = cartInfo.checkout_gift_products[key].product_images;
				giftItem.name = cartInfo.checkout_gift_products[key].product_name;
				giftItem.product_crm_id = cartInfo.checkout_gift_products[key].product_crm_id;
				giftItem.product_mongodb_id = cartInfo.checkout_gift_products[key]._id.$oid; 
				giftItem.item_price = cartInfo.checkout_gift_products[key].price;
				giftItem.price = cartInfo.checkout_gift_products[key].price;
				giftItem.product_billing_model_id = cartInfo.checkout_gift_products[key].product_billing_model_id; 
				giftItem.product_crm_id = cartInfo.checkout_gift_products[key].product_crm_id; 
				giftItem.product_discount_price = cartInfo.checkout_gift_products[key].price;
				giftItem.product_name = cartInfo.checkout_gift_products[key].product_name; 
				giftItem.product_offer_id = cartInfo.checkout_gift_products[key].product_offer_id; 
				giftItem.quantity = 1; 
				giftItem.variant_id = cartInfo.checkout_gift_products[key].shopify_variant_id; 
				updatedGiftItems.push(giftItem);
			}
		});
		
		// If array got empty or false then index set as false in cart-info
		cartInfo.giftItems = (updatedGiftItems && Object.keys(updatedGiftItems).length > 0) ? updatedGiftItems : false;
		this.props.updateGiftItem({ cartInfo: cartInfo });
	}

	handleClearGiftItem() {
		this.setState({ selectedValue : false },function () {
			// Clearing gift items
			ls.set('giftItems', false);
			let cartInfo = this.state.cartInfo;
			cartInfo.giftItems = false;
			this.props.updateGiftItem({ cartInfo: cartInfo });
		});
	}

	renderHtml(){
		let giftItems =  (this.state.cartInfo && this.state.cartInfo !== false && this.state.cartInfo.checkout_gift_products && Object.keys(this.state.cartInfo.checkout_gift_products).length > 0) ? this.state.cartInfo.checkout_gift_products : false;
		let giftProducts = [];

		// Set Gift Products
		if(giftItems !== false){
			Object.keys(giftItems).forEach((key)=>{
				giftProducts.push(
					<div className="custom-control custom-radio" key={giftItems[key]['_id']['$oid']}>
						<input
							id={giftItems[key]['_id']['$oid']}
							name="giftItem"
							type="radio"
							className="custom-control-input"
							value={giftItems[key]['product_crm_id']}
							onClick={this.handleChange}
							/>
						<label className="custom-control-label giftItem span-cursor" htmlFor={giftItems[key]['_id']['$oid']}>
							<p className={"label-position " + ( giftItems[key]['product_name'].length > 24 ? "custom-tooltip": "")} data-title={giftItems[key]['product_name']}>{ Checkout.setEcllipseOnString(giftItems[key]['product_name'], 50) }</p>
						</label>
					</div>				
				);
			});
		
			if(Object.keys(giftProducts).length > 0){
				giftProducts.push(<div className="custom-control custom-radio clear-gift-item" key={Math.random()}>
						<input
							id='clearGift'
							name="giftItem"
							type="radio"
							className="custom-control-input"
							onClick={this.handleChange}
							/>
						<label className='span-cursor' htmlFor="clearGift" title="Clear your gift item">Clear</label>
					</div>);
			}
		}

		// If products not found then show default-message
		if(giftProducts.length < 1){
			giftProducts.push(
			  <div className="custom-control custom-radio text-center text-danger" key={Math.random()}>
				Gift is unavailable
			  </div>
			);
		}
		return <div>
			<div className="contact-information">
				<h3>Choose a FREE gift</h3>
				<div className="contact-form billing-form">
					<div className="change-contact widht99 gift-items">
						{ giftProducts }
					</div>
				</div>
			</div>
		</div>;
	}

	render(){
		// Checked for gift status enable/disable
		let checkout_enable_gift =  (this.state.cartInfo && this.state.cartInfo !== false && this.state.cartInfo.checkout_enable_gift && this.state.cartInfo.checkout_enable_gift === true) ? true : false;
		return  (checkout_enable_gift && checkout_enable_gift === true) ? this.renderHtml() : "";
	} 
}

export default withUnmounted(CheckoutGiftPage);